import { Config } from '../../interfaces/config.interface';
import Logo from './logo.jpg';

const config: Config = {
  name: 'Transformate',
  title: 'Actividades de voluntariado',
  logo: Logo,
  logo_width: '200px',
  has_hours: false,
  theme: {
    primary: '#001273',
    secondary: '#00c8ff',
  },
  // paleta de colores:
  // B900F7
  // 5A0045
  // 5317D1
  // 001273
  // FFB71B
};

export default config;
